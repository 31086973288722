<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("Liste des acomptes") }}</h1>
      <div class="total">{{ getAcompteRows }} {{ $t("ACOMPTE") }}(s)</div>
    </div>

    <div class="content">
      <div class="content-header">
        <searchBar
          :searchText="$t('SEARCH')"
          :searchFunc="acomptes"
          :filtre="{
            entreprise: filtre_entreprise ? filtre_entreprise.id : null,
          }"
          :showFiltre="isSuperAdmin"
          @returnValue="updateSearch($event)"
        ></searchBar>

        <b-modal id="entrepriseModal" ref="entrepriseModal">
          <template #modal-header="{ close }">
            <h5>{{ $t("COMPANY_ID") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>

          <form @submit.prevent="hideModal('entrepriseModal')">
            <div class="center">
              <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
                <searchInput
                  :list="getAllentreprises"
                  :loader="getentrepriseLoading"
                  label="lib"
                  :searchFunc="all_entreprises"
                  @searchfilter="selectentreprise($event)"
                ></searchInput>
              </b-form-group>
            </div>
          </form>
          <template #modal-footer>
            <div class="actionModel">
              <b-button variant="success" @click="setup(true)">
                <div class="block-spinner">
                  {{ $t("Appliquer") }}
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
      <div class="filtre">
        <div class="productTrie" v-if="currententreprise.lib != null">
          {{ currententreprise.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="selectentreprise()"
          />
        </div>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("ACOMPTE") }} N°
              </th>
              <th>
                {{ $t("CREATED_AT") }}
              </th>
              <th>
                {{ $t("MONTANT") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("STATUT") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllAcomptes"
              :key="i"
              @click.prevent.stop="handleAcompte(data)"
            >
              <td aria-colindex="1" role="cell">
                {{ data.num }}
              </td>
              <td aria-colindex="2" role="cell">
                {{ moment(data.created_at).format("DD-MM-YYYY H:mm") }}
              </td>
              <td aria-colindex="3" role="cell">
                {{ data.amount | financial }}
              </td>
              <td>
                <span
                  class="end"
                  style="background: #ffb300"
                  v-if="data.status == 'saved'"
                >
                  {{ $t("PENDING") }}
                </span>
                <span v-else style="background: #e4261b" class="end">
                  {{ $t("CANCEL") }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-modal id="acompteModel" ref="acompteModel" size="xl">
        <template #modal-header="{ close }" v-if="acompteDetail">
          <h5>
            {{ $t("ACOMPTE") }}
            {{ acompteDetail.num }}
          </h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>
        <div v-if="acompteDetail" class="modal-test">
          <div>
            <div class="cours">
              <p
                class="end"
                style="background: #e4261b"
                v-if="acompteDetail.status == 'canceled'"
              >
                {{ $t("CANCEL") }}
              </p>
              <p v-else style="background: #ffb300">
                {{ $t("PENDING") }}
              </p>
              <b-button
                variant="danger"
                @click="showMsgBoxOne"
                v-if="acompteDetail.status != 'canceled'"
              >
                <div class="block-spinner">
                  {{ $t("ANNULER") }} {{ $t("INVOICE") }}
                </div>
              </b-button>
            </div>
          </div>
          <div class="part justify">
            <div class="divborder">
              <p>{{ $t("COORDONNEES_FACTURE") }}</p>
            </div>
            <div class="left">
              <ul>
                <h2 class="textstyle">{{ acompteDetail.entreprise.lib }}</h2>
                <span class="adressinfo">
                  <span class="info textstyle">
                    {{ acompteDetail.entreprise.rue }}
                  </span>
                  <span class="info textstyle">
                    {{ acompteDetail.entreprise.cp }}
                    {{ acompteDetail.entreprise.ville }}
                  </span>
                </span>
                <li>
                  <span class="entrepriseinfo">{{ $t("SIRET") }}</span>
                  <span class="textstyle">{{
                    acompteDetail.entreprise.siret
                  }}</span>
                </li>
                <li>
                  <span class="entrepriseinfo">{{ $t("NUM_TVA") }}</span>
                  <span class="textstyle">{{
                    acompteDetail.entreprise.num_tva
                  }}</span>
                </li>

                <li>
                  <span class="entrepriseinfo">{{ $t("TEL") }}</span>
                  <span>{{ acompteDetail.entreprise.phone ? acompteDetail.entreprise.phone.formated_phone : '' }}</span>
                </li>
                <div class="block">
                  <li class="desclass" v-if="acompteDetail">
                    <span class="title" v-if="acompteDetail.created_at"
                      >{{ $t("DATE_FACTURE") }}:</span
                    >
                    <span
                      >{{
                        moment(acompteDetail.created_at).format(
                          "DD-MM-YYYY H:mm"
                        )
                      }}
                    </span>
                  </li>
                </div>
              </ul>
            </div>
            <div class="right">
              <h1>{{ $t("INVOICE") }} {{ $t("ACOMPTE") }}</h1>
              <div class="infonum">
                <span>N°{{ acompteDetail.num }}</span>
              </div>

              <h2 class="textstyle" v-if="acompteDetail.client">
                {{ acompteDetail.client.prenom }} {{ acompteDetail.client.nom }}
              </h2>
              <span class="adressinfo">
                <span class="info textstyle">{{
                  acompteDetail.client.rue
                }}</span>
                <span class="info textstyle">
                  {{ acompteDetail.client.cp }}
                  {{ acompteDetail.client.ville }}
                </span>
              </span>
              <span>
                <span class="entrepriseinfo">{{ $t("TEL") }}</span>
                <span>{{ acompteDetail.client.phone ? acompteDetail.client.phone.formated_phone : '' }}</span>
              </span>
            </div>
            <div class="divborder">
              <p>{{ $t("DETAILS_FACTURE") }}</p>
            </div>
          </div>
          <div class="table-rapport-style mt-2" v-if="acompteDetail">
            <table class="accompteTable">
              <thead>
                <tr>
                  <th style="border-radius: 5px 0px 0px 0px">
                    {{ $t("WORDING") }}
                  </th>
                  <th>
                    {{ $t("PU TTC") }}
                  </th>
                  <th style="border-radius: 0px 5px 0px 0px">
                    {{ $t("TOTAL TTC") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr role="row" class="ligneNormale">
                  <td>{{ $t("ACOMPTE") }}</td>
                  <td>{{ acompteDetail.amount }} €</td>
                  <td>{{ acompteDetail.amount }} €</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <template #modal-footer>
          <div class="buttondevis">
            <b-button
              variant="success"
              @click="sendEmail()"
              v-if="
                isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
              "
            >
              <div class="block-spinner">
                {{ $t("SEND_BY_EMAIL") }}
              </div>
            </b-button>

            <b-button variant="success" @click="openfac()">
              <div class="block-spinner">
                {{ acompteDetail.facture.numerofactures }}
              </div>
            </b-button>

            <b-button variant="danger" @click="generate()">
              <div class="block-spinner">
                {{ $t("DOWNLOAD") }}
                <div v-if="getFactureLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <b-modal size="lg" centered id="ModelError" ref="ModelError">
        <template #modal-header="{ close }">
          <h5>{{ $t("ERROR") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#fff"
              />
            </svg>
          </b-button>
        </template>
        <div class="messageError">{{ getAvoirError }}</div>
        <template #modal-footer>
          <div class="actionModel">
            <b-button variant="danger" @click="hideModal('ModelError')">
              <div class="block-spinner">
                {{ $t("CLOSE") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getAcompteRows"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getAcompteRows"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getFactureLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { domains } from "@/environment";
import searchInput from "@/components/ui/searchInput";
import searchBar from "../components/ui/searchBar.vue";
import formatMoney from "accounting-js/lib/formatMoney.js";
import CommentMarche from "../components/commentMarche.vue";

export default {
  components: {
    searchBar,
    searchInput,
    CommentMarche,
  },
  data() {
    return {
      search: "",
      acompteDetail: null,
      page: 1,
      perPage: 10,
      currententreprise: {
        id: null,
      },
      filtre_entreprise: {
        id: null,
      },
      box: "",
      downloadUrl: domains.download,
      pdfInfo: null,
    };
  },
  methods: {
    ...mapActions([
      "acomptes",
      "all_entreprises",
      "email_acompte",
      "downloadAcompte",
      "store_avoir",
      "getoneacompte",
    ]),

    customLabel({ title }) {
      return `${title}`;
    },

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    selectentreprise(query) {
      if (query) {
        this.filtre_entreprise = query;
      } else {
        this.filtre_entreprise = {
          lib: null,
        };
      }
      this.setup(true);
      this.hideModal("entrepriseModal");
    },

    async searchPiece() {
      await this.acomptes({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
        entreprise: this.filtre_entreprise.id,
      });
    },
    async triepiece(bool = null) {
      this.favoris = bool;
      if (bool != null) {
        await this.acomptes({
          page: this.page,
          per_page: this.perPage,
          favoris: bool,
          search: this.search,
        });
      } else {
        this.searchPiece();
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    handleAcompte(CAT) {
      this.acompteDetail = { ...CAT };
      this.$refs["acompteModel"].show();
    },

    openfac() {
      var facture = this.acompteDetail.facture;
      this.$router.push("/facture/list?id=" + facture.id);
    },

    showMsgBoxOne() {
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûrs ?", {
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
        })
        .then((value) => {
          if (value) {
            this.store_avoir({
              facture_acompte_id: this.acompteDetail.id,
            })
              .then(() => {
                this.$router.push("/avoir/list");
              })
              .catch(() => {
                this.$refs["ModelError"].show();
              });
          }
        });
    },

    sendEmail() {
      this.email_acompte(this.acompteDetail.id).then(() => {
        this.box = "";
        this.$bvModal.msgBoxOk(this.$t("FACTURE_SENDED"), {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          centered: true,
        });
      });
    },

    generate() {
      this.downloadAcompte(this.acompteDetail.id).then((response) => {
        this.pdfInfo = response;
        this.download();
      });
    },
    download() {
      window.open(this.downloadUrl + this.pdfInfo, "_blank");
    },

    pagination(paginate) {
      this.page = paginate;
      this.searchPiece();
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.currententreprise = this.filtre_entreprise;
      this.$refs["entrepriseModal"].hide();

      if (this.getOnlineUser) {
        if (this.isSuperAdmin) {
          this.acomptes({
            page: this.page,
            per_page: this.perPage,
            entreprise: this.filtre_entreprise.id,
          });
        } else {
          this.acomptes({
            page: this.page,
            per_page: this.perPage,
            entreprise: this.getOnlineUser.entreprise.id,
          });
        }
      }
    },
  },

  mounted() {
    this.setup(true);
    if (this.isSuperAdmin)
      this.all_entreprises({
        page: this.page,
        per_page: 1000,
        search: this.search,
      });
    if (this.$route.query.id) {
      this.getoneacompte(this.$route.query).then((response) => {
        if (response) this.handleAcompte(this.getAcompte);
        else alert("Acompte Not Found !");
      });
    }
  },

  filters: {
    financial: function (value, noSymbol = false) {
      return formatMoney(value, {
        symbol: noSymbol ? "" : "€",
        thousand: " ",
        precision: 2,
        decimal: ",",
        format: "%v %s",
      });
    },
  },

  computed: {
    ...mapGetters([
      "getAllAcomptes",
      "getAcompteRows",
      "getFactureLoading",
      "getTabs",
      "getOnlineUser",
      "getAllentreprises",
      "getSelectedLang",
      "getentrepriseLoading",
      "getAcompte",
      "getAvoirError",
    ]),

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
    computedAllentreprises() {
      var list = [
        {
          lib: "TOUTES LES ENTREPRISES",
          id: 1,
        },
      ];
      if (this.getAllentreprises)
        this.getAllentreprises.forEach((element) => {
          if (element.id != 1) list.push(element);
        });
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  padding: 15px 0 0;
  flex-flow: column;
  align-items: center;
  .messageError {
    color: #e4261b;
    font-size: 16px;
    background-color: #fff;
    border-color: #fff;
  }
  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.cours {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  p {
    margin: 0;
    color: #fff;
    background-color: #d0cccc;
    width: 81px;
    height: 24px;
    font-size: 13px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    font-size: 14px;
  }
}

.justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.part {
  padding: 8px 0;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #515151;
    word-wrap: break-word;
  }
  .remarqueblock {
    margin-bottom: 5px;
  }
  .cadre {
    background-color: #f8f8f8;
    margin-top: 1%;
    margin-bottom: 1%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    padding: 13px;
  }
  .left {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    width: 30%;
  }

  .right {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    width: 30%;
    p {
      float: right;
      padding: 8px;
      background-color: #d0cccc;
      color: #000;
      width: 50%;
      font-weight: 600;
      text-align: center;
      margin: 0 0 4px;
    }
  }

  .fact {
    font-size: 16px;
    font-weight: 600;
    color: #28367a;
  }

  h2,
  h3 {
    font-weight: 600;
  }

  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 16px;
    margin: 0;
  }

  .part-title {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }

  ul {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #515151;

      span {
        display: block;
        width: 50%;
        font-size: 14px;
      }

      .title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
        color: #393939;
        font-weight: 600;
        font-size: 14px;
      }
    }

    .traveauxtitle {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
      padding: 0 15px;
    }
  }
  .flexEnd {
    display: flex;
    justify-content: flex-end;
    .subtable {
      .complet {
        @media only screen and (max-width: 900px) {
          margin-right: 10%;
          width: 100%;
        }
        border-collapse: separate;
        margin-right: 10%;
        width: 100%;
        height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        tr {
          height: 24px;
        }

        .thead {
          @media only screen and (max-width: 900px) {
            padding: 6px !important;
          }
          background-color: #6472b3;
          color: #fff;
          max-width: 118px;
          height: 32px;
        }
        td {
          color: #000;
          background-color: #e9eaf2;
          width: 118px;
        }
      }
    }
  }

  .tabledonne {
    overflow-x: auto;
    margin-bottom: 4px;
    .libblock {
      font-weight: 800;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  table {
    border-collapse: separate;
    th {
      font-weight: 400;
      font-size: 12px;
      background-color: #6472b3;
      padding: 4px;
      color: #fff;
      border: 1px solid #6472b3;
      text-align: center;
    }
    td {
      text-align: center;
      padding: 4px;
      background-color: #e9eaf2;
    }
    tr {
      height: 24px;
    }
  }
}

.justify {
  padding: 13px;
  .left {
    h2 {
      color: #28367a;
      font-weight: 700;
      margin-bottom: 0px;
    }
    .entrepriseinfo {
      color: #000;
      font-weight: 600;
    }
    .title {
      color: #28367a;
      font-weight: 700;
    }
    .adressinfo {
      display: grid;
    }
    .info {
      color: #515151;
    }
    .block {
      margin-top: 10px;
    }
  }
  .right {
    h1 {
      font-size: 32px;
    }
    .infonum {
      display: grid;
    }
    .span {
      color: #515151;
    }
    h2 {
      color: #28367a;
      font-weight: 700;
    }
    .adressinfo {
      display: grid;
    }
    .info {
      color: #515151;
    }
    .entrepriseinfo {
      color: #000;
      font-weight: 600;
    }
  }

  .divborder {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    color: #28367a;
    width: 100%;
    p {
      margin: 0px 10px;
    }
  }
  .divborder::after,
  .divborder::before {
    content: "";
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    height: 1px;
    background-color: #d8d8d8;
  }
}

.buttondevis {
  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 10px 0;
    justify-content: center;
    flex-wrap: wrap;
  }
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
  border-top: 1px solid #d8d8d8;
  gap: 15px 0;

  button {
    @media only screen and (max-width: 900px) {
      min-width: 47%;
      max-width: 47%;
      font-size: 12px;
      margin: 4px 4px 0 0;
      padding: 0;
    }
    min-width: 24%;
    height: 42px;
    font-size: 14px;

    .block-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
